import React, { useState } from 'react';
import './index.css';

import { IOpenAPI } from './interface/IOpenApi';

import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export default function App() {
  const [inputUrl, setInputUrl] = useState(getBaseAPIUrl());
  const [inputAPIKey, setInputAPIKey] = useState('');
  const [APIUrl, setAPIUrl] = useState(inputUrl);

  return (
    <div>
      <div className="grid grid-cols-4 bg-[#023a47] py-2">
        <div className="self-center justify-self-center">
          <img
            height="40"
            src="https://www.autoflex.nl/img/logo.svg"
            alt="Autoflex logo"
          />
        </div>
        <div className="col-span-2">
          <label className="grid">
            <span className="text-sm font-medium text-white">URL</span>
            <input
              className="my-1 p-1 bg-white border border-gray-200 rounded"
              type="url"
              id="api-url"
              value={inputUrl}
              onChange={(event) => setInputUrl(event.target.value)}
            />
          </label>

          <label className="grid">
            <span className="text-sm font-medium text-white">API-Key</span>
            <input
              className="my-1 p-1 bg-white border border-gray-200 rounded"
              type="text"
              id="api-key"
              value={inputAPIKey}
              onChange={(event) => setInputAPIKey(event.target.value)}
              placeholder="00000000-0000-0000-0000-000000000000"
            />
          </label>
        </div>
        <div className="self-center justify-self-center">
          <button
            onClick={() => {
              setAPIUrl(`${inputUrl}?api_key=${inputAPIKey}`);
            }}
            className="btn-hexagon"
          >
            <div className="bg-white p-2">Explore</div>
          </button>
        </div>
      </div>

      <SwaggerUI
        docExpansion="none"
        plugins={[TagsSortPlugin]}
        requestInterceptor={(res) => {
          //Method to alter the fields param to do not send empty value ','
          var url = new URL(res.url);
          if (url.searchParams.has('fields')) {
            const fields = url.searchParams.get('fields');
            if (fields.length === 0) {
              url.searchParams.delete('fields');
            } else {
              let fixedFields = fields.split(',').filter((x) => x.length !== 0);
              url.searchParams.set('fields', fixedFields.join(','));
            }
            res.url = url.href;
          }
          return res;
        }}
        url={APIUrl}
      />
    </div>
  );
}

const TagsSortPlugin = () => {
  return {
    statePlugins: {
      spec: {
        wrapActions: {
          updateJsonSpec: (oriAction, system) => (openapi: IOpenAPI) => {
            openapi.tags.sort((a, b) => {
              if (a.name.toUpperCase() === 'AUTHENTICATE') {
                return -1;
              }
              if (b.name.toUpperCase() === 'AUTHENTICATE') {
                return 0;
              }
              if (a.name > b.name) {
                return 1;
              }
              if (b.name > a.name) {
                return -1;
              }
              return 0;
            });

            return oriAction(openapi); // don't forget! otherwise, Swagger UI won't update
          },
        },
      },
    },
  };
};

const getBaseAPIUrl = function (): string {
  if (document.location.hostname === 'localhost')
    return 'http://localhost:8080/servoy-service/velocity/webservice_v2/util/openapi';

  const TLD = document.location.hostname.split('.')[2];
  if (!TLD)
    return 'https://www.autoflex10.dev/autoflex/servoy-service/velocity/webservice_v2/util/openapi';

  return `https://api.autoflex10.${TLD}/v2/util/openapi`;
};
